@use '../../styles/mixins' as mixins;

.search-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {

		.search-block__content {
			text-align: center;
			max-width: 475px;
			margin: 0 auto;
			
		}

		.search-form {

			position: relative;
			display: flex;
			max-width: 900px;
			margin: var(--wp--preset--spacing--40) auto;
			
			label.screen-reader-text {
				position: absolute;
				opacity: 0;
				z-index: -1;
			}

			input {
				font-size: var(--wp--preset--font-size--body);
				font-family: var(--wp--preset--font-family--bnpp-sans);
				padding: var(--wp--preset--spacing--40) var(--wp--preset--spacing--60);

				&:not(#searchsubmit) {
					padding-left: var(--wp--preset--spacing--70);
				}
			}

			input#s {
				flex: 1;
				background-color: #003132;
				border: solid 1px #0F4139;
				border-right: none;
				padding-left: var(--wp--preset--spacing--70);
			}

			input#searchsubmit {
				--buttonHoverColour: #004E31;
    			background-color: var(--wp--preset--color--bnpp-green);
				border: none;
				cursor: pointer;
				transition: 300ms;
				text-decoration: none;

				&:hover {
					background-color: var(--buttonHoverColour);
				}
			}

			&::before {
				content: "";
				--circleSize: 16px;
				position: absolute;
				top: 50%;
				left: var(--wp--preset--spacing--40);
				border-radius: 50%;
				border: solid 2px var(--wp--preset--color--bnpp-green);
				width: var(--circleSize);
				height: var(--circleSize);
				transform: translateY(-70%);
			}

			&::after {
				content: "";
				position: absolute;
				--handleWidth: 12px;
				top: 50%;
				left: calc(var(--wp--preset--spacing--40) + var(--handleWidth));
				width: var(--handleWidth);
				height: 2px;
				border-radius: 50px;
				background-color: var(--wp--preset--color--bnpp-green);
				transform: translateY(5px) rotate(45deg);
			}

		}

		.search-block__asset-class-links {
			display: flex;
			gap:  var(--wp--preset--spacing--40);
			justify-content: center;
			flex-wrap: wrap;
			max-width: 600px;
			margin: var(--wp--preset--spacing--60) auto 0;

			.bnpp-pill {
				--buttonHoverColour: #004E31;
				display: inline-block;
				background: var(--wp--preset--color--bnpp-green-tint-30);
				border-radius: 50px;
				padding: 0.2rem var(--wp--preset--spacing--40);
				font-weight: 300;
				cursor: pointer;
				transition: 300ms;

				&:hover {
					background-color: var(--buttonHoverColour);
				}
			}
		}
	}

	h2 {
		padding-top: var(--wp--preset--spacing--50);
	}

	&.light {

		input:not(#search) {
			color: var(--wp--preset--color--white);
		}
	}

}